import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealOneOnOne', 'textRevealTakeInterest', 'textRevealDinnerTogether', 'textRevealKnowFriends'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const TextCustom = makeShortcode("TextCustom");
const BasicAudio = makeShortcode("BasicAudio");
const ButtonDialog = makeShortcode("ButtonDialog");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how"
    }}>{`How?`}</h1>
    <p>{`Here are some ways to stay connected (click text below to reveal):`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealOneOnOne" header="Regularly spend one-on-one time together" mdxType="TextReveal">
      <p>
        Do you have a regular time for your teenager?
        <br />
        What does your teenager like to do with you?
        <br />
        What time of day do they prefer to spend with you?
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealTakeInterest" header="Take an active interest in what your teenager is doing at school" mdxType="TextReveal">
      <p>
        Do you talk about school life?
        <br />
        Do they do any extra-curricular activities?
        <br />
        Do you know which subjects they like or dislike?
        <br />
        Do you know if they need extra help with any subjects?
        <br />
        Do you know the names of their teachers?
        <br />
        Do you know which subjects are their strongest?
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealDinnerTogether" header="Regularly try to eat dinner together as a family" mdxType="TextReveal">
      <p>
        Do you have a regular dinner time?
        <br />
        Are the TV and other electronic devices off at dinner time?
        <br />
        Do you eat together as a family?
        <br />
        Do you sit at the table all together?
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealKnowFriends" header="Get to know your teenager's friends" mdxType="TextReveal">
      <p>
        Do you know who they are?
        <br />
        Do you know what they do together?
        <br />
        Have you met them?
      </p>
    </TextReveal>
  </Grid>
    </Grid>
    <br />
    <TextCustom customColor="blue" mdxType="TextCustom">
  <i>What are a few good activities you could do with your mum and dad?</i> Hear what Sam, 14 years, says…
    </TextCustom>
    <BasicAudio src="/audios/module_4_audio_clip.mp3" mdxType="BasicAudio" />
    <ButtonDialog linkText="Read Transcript" title="What are a few good activities you could do with your mum and dad?" mdxType="ButtonDialog">
  <p>Sam, 14 years, says…</p>
  <p>
    The things I like to do with my Mum and Dad are stuff like making pizza together, going and getting late night ice
    cream, playing football in the park, or playing video games, or board games like Scrabble at home with whole family.
  </p>
  <p>
    On weekends, I like it when we go out and get take away together, then we watch a movie at home that we all want to
    watch.
  </p>
  <p>
    Bowling is good too, and I <i>always</i> like to go away for the weekend.
  </p>
    </ButtonDialog>
    <SingleImage smallGridSize={8} gridSize={8} src="/images/shared/Oriental_Fa_Son_ball.svg" alt="Father and son playing ball" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      